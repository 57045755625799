*, :before, :after {
  box-sizing: border-box;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--body-bg) / var(--tw-bg-opacity));
  color: var(--body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  grid-template-rows: auto 1fr;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: .8125rem;
  display: grid;
  overflow: hidden;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

@font-face {
  font-family: Roboto;
  src: url("roboto-light.bc3a626a.woff2") format("woff2"), url("roboto-light.9ef36746.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("roboto-regular.bba79067.woff2") format("woff2"), url("roboto-regular.0205d996.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("roboto-medium.1c9fa88e.woff2") format("woff2"), url("roboto-medium.789fdce0.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

*, :focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

code {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
  font-size: .75rem;
}

.pre {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  border-radius: .375rem;
  padding: 1rem;
  font-size: .75rem;
  overflow-x: auto;
}

:is([data-theme="dark"] .pre) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

#content {
  display: flex;
  overflow: hidden;
}

@media (width >= 1280px) {
  #content {
    padding-left: var(--sidebar-width);
  }
}

#content:not(.content-alt) {
  flex-direction: column;
}

@media (width >= 1280px) {
  #content.content-alt {
    padding-left: var(--sidebar-min-width);
  }
}

.content-header {
  height: 3rem;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

@media print {
  .content-header {
    display: none;
  }
}

.aside {
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 4rem;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width >= 768px) {
  .aside {
    width: 16rem;
  }
}

.aside-screen-xl {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 1280px) {
  .aside-screen-xl {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    padding-top: 0;
    position: relative;
  }
}

.aside-screen-md {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 768px) {
  .aside-screen-md {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    padding-top: 0;
    position: relative;
  }
}

[data-content="body"] {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--body-bg) / var(--tw-bg-opacity));
  flex-direction: column;
  flex: 1;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

.content-sidebar-toggled [data-content="sidebar"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.content-sidebar-toggled [data-content="list"] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 1280px) {
  .content-sidebar-toggled [data-content="list"] {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.content-sidebar-toggled [data-content="body"] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 768px) {
  .content-sidebar-toggled [data-content="body"] {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.content-list-toggled [data-content="list"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.content-list-toggled [data-content="body"] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 768px) {
  .content-list-toggled [data-content="body"] {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.content-body-toggled [data-content="list"] {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 768px) {
  .content-body-toggled [data-content="list"] {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.content-body-toggled [data-content="body"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.table {
  width: 100%;
  text-align: left;
  display: table;
}

.table th, .table td {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(var(--theme-light-100) / var(--tw-border-opacity));
  padding: .75rem;
}

:is([data-theme="dark"] .table th), :is([data-theme="dark"] .table td) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-200) / var(--tw-border-opacity));
}

.table th {
  font-weight: 500;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

#sidebar {
  z-index: 19;
  height: 100%;
  width: var(--sidebar-width);
  --tw-translate-x: calc(var(--sidebar-width) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--body-bg) / var(--tw-bg-opacity));
  color: var(--text-heading);
  padding-top: 4rem;
  position: fixed;
  overflow: hidden;
}

#sidebar:hover {
  width: var(--sidebar-width);
}

:is([data-theme="dark"] #sidebar) {
  border-width: 0;
}

@media print {
  #sidebar {
    display: none;
  }
}

@media (width >= 1280px) {
  #sidebar {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #sidebar.sidebar-min:not(:hover) {
    width: var(--sidebar-min-width);
  }

  #sidebar.sidebar-min:not(:hover) .menu-title {
    color: #0000;
  }

  #sidebar.sidebar-min:not(:hover) .menu-title:before {
    content: var(--tw-content);
    opacity: 1;
  }

  #sidebar.sidebar-min:not(:hover) .menu-text {
    opacity: 0;
  }
}

#sidebar.sidebar-min:not(:hover) .footer-text, #sidebar.sidebar-min:not(:hover) .sub-menu-active {
  display: none;
}

#sidebar.sidebar-min:hover {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(var(--theme-light-300) / var(--tw-border-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

:is([data-theme="dark"] #sidebar.sidebar-min:hover) {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
  border-width: 0;
}

.sidebar-toggled #sidebar {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

:is([data-theme="dark"] .sidebar-toggled #sidebar) {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

@media (width >= 1280px) {
  .menu-title:before {
    --tw-text-opacity: 1;
    color: rgb(106 140 163 / var(--tw-text-opacity));
    opacity: 0;
    --tw-content: "...";
    content: var(--tw-content);
    font-size: 1.0625rem;
    font-weight: 500;
    transition-property: all;
    transition-duration: .1s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    position: absolute;
    top: -.625rem;
  }
}

#search {
  height: 4rem;
  width: 100%;
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
  align-items: center;
  padding-left: .875rem;
  padding-right: .875rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 1024px) {
  #search {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    opacity: 1;
    position: relative;
  }
}

#search.search-toggled {
  z-index: 10;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.dropdown-header [data-qa-dropdown-menu] {
  height: calc(100vh - 4rem);
  width: 100%;
  max-width: calc(100% - 1.5rem);
  margin-top: -.5rem;
  right: .5rem;
  overflow: hidden;
}

@media (width >= 640px) {
  .dropdown-header [data-qa-dropdown-menu] {
    max-width: 355px;
  }
}

.dropdown-header .simplebar-content {
  height: 100%;
  flex-direction: column;
  display: flex;
}

[data-qa-dropdown-menu] {
  z-index: 50;
  min-width: 7rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  color: var(--body-color);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgb(var(--theme-light-500) / .2);
  --tw-shadow: var(--tw-shadow-colored);
  border-radius: .375rem;
  display: none;
  position: absolute;
  top: 100%;
}

:is([data-theme="dark"] [data-qa-dropdown-menu]) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
  border-width: 0;
}

[data-qa-dropdown] {
  display: inline-block;
}

[data-qa-dropdown].active [data-qa-dropdown-menu] {
  display: block;
}

[data-qa-dropdown-toggle] * {
  pointer-events: none;
}

[data-qa-dropdown-item] {
  white-space: nowrap;
  color: var(--body-color);
  border-radius: .2rem;
  align-items: center;
  padding: .375rem 1rem .375rem .625rem;
  font-size: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

[data-qa-dropdown-item]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  color: var(--text-heading);
}

:is([data-theme="dark"] [data-qa-dropdown-item]:hover) {
  background-color: rgb(var(--theme-dark-300) / .5);
}

[data-qa-dropdown-item] > .icon {
  margin-right: .5rem;
}

[data-qa-modal-window] {
  z-index: 9999;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: #0003;
  place-content: center;
  padding: 1.25rem;
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

[data-qa-modal-dialog] {
  width: 100%;
  max-width: 32rem;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .375rem;
  flex-shrink: 0;
  margin: auto;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  overflow-y: auto;
}

:is([data-theme="dark"] [data-qa-modal-dialog]) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

[data-qa-modal-dismiss] > * {
  pointer-events: none;
}

.caret:after {
  height: 0;
  width: 0;
  --tw-content: "";
  content: var(--tw-content);
  border-top: 0 solid #0000;
  border-bottom: 7px solid;
  border-left: 7px solid #0000;
  border-right: 0 solid #0000;
  position: absolute;
  top: 0;
  right: 0;
}

.card {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .375rem;
}

:is([data-theme="dark"] .card) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-100) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

textarea, input {
  font-size: .75rem;
}

.form-control {
  height: 2.25rem;
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-300) / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-radius: .2rem;
  padding-left: .75rem;
  padding-right: .75rem;
}

:is([data-theme="dark"] .form-control) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-300) / var(--tw-border-opacity));
}

.form-control:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  opacity: .7;
}

:is([data-theme="dark"] .form-control:disabled) {
  background-color: rgb(var(--theme-dark-200) / .6);
}

.form-control:not([class*="border-"]):focus {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-500) / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-light-100) / var(--tw-ring-opacity));
}

:is([data-theme="dark"] .form-control:not([class*="border-"]):focus) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-500) / var(--tw-border-opacity));
  --tw-ring-color: rgb(var(--theme-dark-200) / .5);
}

div.form-control:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-500) / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-light-100) / var(--tw-ring-opacity));
}

:is([data-theme="dark"] div.form-control:focus-within) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-500) / var(--tw-border-opacity));
  --tw-ring-color: rgb(var(--theme-dark-200) / .5);
}

textarea.form-control {
  height: auto;
  resize: none;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.select {
  position: relative;
}

.select:after {
  pointer-events: none;
  height: 100%;
  --tw-content: "";
  content: var(--tw-content);
  align-items: center;
  font-family: Material Symbols Rounded;
  font-size: 1.25rem;
  font-weight: 300;
  display: flex;
  position: absolute;
  top: 0;
  right: .375rem;
}

select.form-control {
  -webkit-appearance: none;
  appearance: none;
}

.checkbox, .radio {
  -webkit-appearance: none;
  appearance: none;
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  background-position: center;
  background-repeat: no-repeat;
  border-width: 1.5px;
  border-color: currentColor;
  flex-shrink: 0;
  margin-top: -1px;
}

.checkbox:not(:disabled):hover, .checkbox:not(:disabled):focus, .radio:not(:disabled):hover, .radio:not(:disabled):focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-light-200) / var(--tw-ring-opacity));
}

:is([data-theme="dark"] .checkbox:not(:disabled):hover), :is([data-theme="dark"] .checkbox:not(:disabled):focus), :is([data-theme="dark"] .radio:not(:disabled):hover), :is([data-theme="dark"] .radio:not(:disabled):focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-dark-200) / var(--tw-ring-opacity));
}

.checkbox:disabled, .radio:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  opacity: .7;
}

:is([data-theme="dark"] .checkbox:disabled), :is([data-theme="dark"] .radio:disabled) {
  background-color: rgb(var(--theme-dark-200) / .6);
}

.checkbox {
  --checkbox-checked-bg: url("checkbox-checked.ee9b0798.svg");
  height: 16px;
  width: 16px;
  background-size: 10px;
  border-radius: .125rem;
}

.checkbox:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
  background-image: var(--checkbox-checked-bg);
  border-width: 0;
}

:is([data-theme="dark"] .checkbox:checked) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.radio {
  height: 17px;
  width: 17px;
  border-radius: 9999px;
}

.radio:checked {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-color) / var(--tw-border-opacity));
  background-image: var(--radio-checked-bg);
}

:is([data-theme="dark"] .radio:checked) {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.switch-checkbox {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.switch-checkbox:not(:disabled):hover ~ .switch-label:before, .switch-checkbox:not(:disabled):active ~ .switch-label:before, .switch-checkbox:not(:disabled):focus ~ .switch-label:before {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-light-200) / var(--tw-ring-opacity));
}

:is([data-theme="dark"] .switch-checkbox:not(:disabled):hover ~ .switch-label):before, :is([data-theme="dark"] .switch-checkbox:not(:disabled):active ~ .switch-label):before, :is([data-theme="dark"] .switch-checkbox:not(:disabled):focus ~ .switch-label):before {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-dark-200) / var(--tw-ring-opacity));
}

.switch-checkbox:disabled ~ .switch-label:before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  opacity: .7;
}

:is([data-theme="dark"] .switch-checkbox:disabled ~ .switch-label):before {
  background-color: rgb(var(--theme-dark-200) / .6);
}

.switch-checkbox:disabled ~ .switch-label:after {
  opacity: .7;
}

.switch-checkbox:checked ~ .switch-label:before {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-color) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .switch-checkbox:checked ~ .switch-label):before {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.switch-checkbox:checked ~ .switch-label:after {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  top: .25rem;
}

:is([data-theme="dark"] .switch-checkbox:checked ~ .switch-label):after {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-500) / var(--tw-bg-opacity));
}

.switch-label {
  align-items: center;
  display: flex;
  position: relative;
}

.switch-label:before {
  isolation: isolate;
  height: 1.25rem;
  width: 2.25rem;
  --tw-border-opacity: 1;
  border-width: 1.5px;
  border-color: rgb(106 140 163 / var(--tw-border-opacity));
  --tw-content: "";
  content: var(--tw-content);
  border-radius: 9999px;
  margin-right: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.switch-label:after {
  height: .75rem;
  width: .75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(106 140 163 / var(--tw-bg-opacity));
  --tw-content: "";
  content: var(--tw-content);
  border-radius: 9999px;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: .25rem;
  left: .25rem;
}

button:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn {
  height: 2rem;
  cursor: pointer;
  border-radius: .2rem;
  flex-shrink: 0;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn > .icon {
  margin-left: -2px;
}

.btn.icon {
  height: 2rem;
  width: 2rem;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.btn-close {
  height: 1.5rem;
  width: 1.5rem;
  background-image: var(--close-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 9px;
  border-radius: 9999px;
}

.nav-scroll {
  height: 2.25rem;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: scroll;
}

.nav-scroll::-webkit-scrollbar {
  display: none;
}

.nav-scroll > li > a, .nav-scroll > a {
  height: 100%;
  text-align: left;
  border-radius: .2rem;
  padding: .5rem .75rem;
  display: inline-flex;
  position: relative;
}

.nav-scroll > li > a:hover, .nav-scroll > a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .nav-scroll > li > a:hover), :is([data-theme="dark"] .nav-scroll > a:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

.nav-scroll > li > a[aria-selected="true"], .nav-scroll > li > a.active, .nav-scroll > a[aria-selected="true"], .nav-scroll > a.active {
  --tw-text-opacity: 1;
  color: rgb(var(--theme-color) / var(--tw-text-opacity));
}

.nav-scroll > li > a[aria-disabled="true"], .nav-scroll > a[aria-disabled="true"] {
  pointer-events: none;
}

.nav-hover > li > a {
  height: 2.25rem;
  white-space: nowrap;
  border-radius: .2rem;
  align-items: center;
  margin-bottom: 1px;
  padding-left: .625rem;
  padding-right: .625rem;
  transition-duration: .3s;
  display: flex;
}

.nav-hover > li > a:not(.active):hover {
  background-color: rgb(var(--theme-light-200) / .6);
}

:is([data-theme="dark"] .nav-hover > li > a:not(.active):hover) {
  background-color: rgb(var(--theme-dark-100) / .6);
}

.nav-hover > li > a.active {
  background-color: rgb(var(--theme-color) / .1);
  --tw-text-opacity: 1;
  color: rgb(var(--theme-color) / var(--tw-text-opacity));
  font-weight: 500;
}

.nav-hover > li > a > .icon {
  flex-shrink: 0;
  margin-right: .75rem;
  font-size: 1.15rem;
}

[data-qa-collapse-item] {
  transition-property: height;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

#map-sessions-country .jvm-region[fill="INITIAL_FILL"] {
  fill: var(--widget-session-country-initial-fill);
}

#map-sessions-country .jvm-region:not([fill="INITIAL_FILL"]) {
  fill: var(--widget-session-country-active-fill);
}

#map-active-visitors .jvm-region[fill="INITIAL_FILL"] {
  fill: var(--widget-active-visitor-initial-fill);
}

#map-active-visitors .jvm-region:not([fill="INITIAL_FILL"]) {
  fill: var(--widget-active-visitor-active-fill);
}

#map-sales-region .jvm-region[fill="INITIAL_FILL"] {
  fill: var(--widget-sales-region-initial-fill);
}

#map-sales-region .jvm-region:not([fill="INITIAL_FILL"]) {
  fill: var(--widget-sales-region-active-fill);
}

.faq-header {
  --faq-header-bg: url("faq-header-bg.1b314328.svg");
  isolation: isolate;
  background-image: var(--faq-header-bg);
  background-repeat: repeat-x;
  position: relative;
}

.faq-header:before {
  pointer-events: none;
  z-index: -1;
  height: 6rem;
  width: 100%;
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #f7fafe var(--tw-gradient-to-position);
  --tw-content: "";
  content: var(--tw-content);
  position: absolute;
  bottom: 0;
  left: 0;
}

:is([data-theme="dark"] .faq-header):before {
  --tw-gradient-to: #22313a var(--tw-gradient-to-position);
}

@font-face {
  font-family: Material Symbols Rounded;
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url("material-symbols-rounded.83b633d2.woff2") format("woff2");
}

.material-symbols-rounded {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-family: Material Symbols Rounded;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.icon {
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  flex-shrink: 0;
  font-family: Material Symbols Rounded;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 401;
  line-height: 1.25;
}

:is([data-theme="dark"] .icon) {
  font-weight: 300;
}

[data-simplebar] {
  height: 100%;
  overflow-y: auto;
}

[data-simplebar] .simplebar-track.simplebar-vertical {
  width: .5rem;
}

[data-simplebar] .simplebar-track.simplebar-horizontal {
  height: .5rem;
}

[data-simplebar] .simplebar-scrollbar:before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-300) / var(--tw-bg-opacity));
  border-radius: .2rem;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:is([data-theme="dark"] [data-simplebar] .simplebar-scrollbar):before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

[data-simplebar] .simplebar-scrollbar.simplebar-visible:before, [data-simplebar] .simplebar-scrollbar.simplebar-hover:before {
  opacity: 1;
}

[data-simplebar] .simplebar-placeholder {
  width: 100% !important;
}

.swal2-container .swal2-popup {
  width: 24rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .75rem;
  padding-bottom: 1.5rem;
}

:is([data-theme="dark"] .swal2-container .swal2-popup) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

.swal2-container .swal2-icon {
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  margin-top: .75rem !important;
  margin-bottom: -.75rem !important;
  animation: none !important;
}

.swal2-container .swal2-icon.swal2-warning {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.swal2-container .swal2-title {
  color: var(--text-heading);
  padding-top: .25rem;
  font-size: .9375rem;
  font-weight: 500;
}

.swal2-container .swal2-html-container {
  color: var(--body-color);
  margin-top: .5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: .8125rem;
  line-height: 1.5;
}

.swal2-container .swal2-actions .swal2-styled {
  border-radius: .2rem;
  font-size: .75rem;
  font-weight: 400;
}

.swal2-container .swal2-actions .swal2-styled:hover {
  opacity: .9;
}

.swal2-container .swal2-actions .swal2-styled.swal2-confirm {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
  background-color: #3b82f61a;
}

.swal2-container .swal2-actions .swal2-styled.swal2-confirm:hover {
  background-color: #3b82f633;
  background-image: none;
}

.swal2-container .swal2-actions .swal2-styled.swal2-confirm:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.swal2-container .swal2-actions .swal2-styled.swal2-cancel {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  background-color: #0000;
  font-weight: 400;
}

.swal2-container .swal2-actions .swal2-styled.swal2-cancel:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  color: var(--text-heading);
  background-image: none;
}

.swal2-container .swal2-actions .swal2-styled.swal2-cancel:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is([data-theme="dark"] .swal2-container .swal2-actions .swal2-styled.swal2-cancel):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

.swal2-container .swal2-actions .swal2-styled.swal2-deny {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
  background-color: #ef44441a;
}

.swal2-container .swal2-actions .swal2-styled.swal2-deny:hover {
  background-color: #ef444433;
  background-image: none;
}

.swal2-container .swal2-actions .swal2-styled.swal2-deny:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.jvm-tooltip {
  background-color: var(--tooltip-bg);
  white-space: nowrap;
  color: #fff;
  border-radius: .15rem;
  padding: .5rem .65rem;
  font-family: Roboto, sans-serif;
  font-size: smaller;
  display: none;
  position: absolute;
  box-shadow: 1px 2px 12px #0003;
  color: var(--tooltip-color) !important;
}

svg {
  touch-action: none;
}

image, text, .jvm-zoomin, .jvm-zoomout {
  -webkit-user-select: none;
  user-select: none;
}

.jvm-tooltip.active {
  display: block;
}

@keyframes jvm-line-animation {
  from {
    stroke-dashoffset: 250px;
  }
}

.chart-bordered:before {
  pointer-events: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
  --tw-content: "";
  content: var(--tw-content);
  border-radius: .375rem;
  position: absolute;
}

:is([data-theme="dark"] .chart-bordered):before {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-200) / var(--tw-border-opacity));
}

.text-editor {
  display: grid;
}

.text-editor .ql-snow.ql-toolbar {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-width: 0;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
  padding: .5rem;
}

:is([data-theme="dark"] .text-editor .ql-snow.ql-toolbar) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

.text-editor .ql-snow.ql-toolbar .ql-formats, [data-qa-dropdown-item] > .text-editor .ql-snow.ql-toolbar button {
  margin-right: .5rem;
}

.btn > .text-editor .ql-snow.ql-toolbar button {
  margin-left: -2px;
}

.btn.text-editor .ql-snow.ql-toolbar button {
  height: 2rem;
  width: 2rem;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.nav-hover > li > a > .text-editor .ql-snow.ql-toolbar button {
  flex-shrink: 0;
  margin-right: .75rem;
  font-size: 1.15rem;
}

.text-editor .ql-snow.ql-toolbar button {
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  height: 1.5rem;
  width: 1.5rem;
  color: var(--body-color);
  border-radius: .2rem;
  flex-shrink: 0;
  place-content: center;
  margin: 1px;
  padding: 0;
  font-family: Material Symbols Rounded;
  font-size: .9375rem;
  font-style: normal;
  font-weight: 401;
  line-height: 1.25;
  display: grid;
}

:is([data-theme="dark"] .text-editor .ql-snow.ql-toolbar button) {
  font-weight: 300;
}

.carousel-prev.text-editor .ql-snow.ql-toolbar button, .carousel-next.text-editor .ql-snow.ql-toolbar button {
  font-size: .8125rem;
}

.text-editor .ql-snow.ql-toolbar button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  color: var(--body-color);
}

:is([data-theme="dark"] .text-editor .ql-snow.ql-toolbar button:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

.text-editor .ql-snow.ql-toolbar button > svg {
  display: none;
}

.text-editor .ql-snow.ql-toolbar button.ql-active {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
  color: var(--text-heading);
}

:is([data-theme="dark"] .text-editor .ql-snow.ql-toolbar button.ql-active) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

.text-editor .ql-snow.ql-container {
  min-height: 3rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-width: 0;
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

:is([data-theme="dark"] .text-editor .ql-snow.ql-container) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

.text-editor .ql-snow .ql-editor {
  font-family: Roboto, sans-serif;
  font-size: .75rem;
}

.text-editor .ql-snow .ql-editor:before {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  font-size: .75rem;
  font-style: normal;
}

.text-editor .ql-snow .ql-tooltip {
  --tw-shadow: var(--dropdown-menu-shadow);
  --tw-shadow-colored: var(--dropdown-menu-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: 9999px;
  margin: 0;
  padding: .25rem .375rem .25rem .25rem;
  top: -.75rem !important;
  left: .5rem !important;
}

.text-editor .ql-snow .ql-tooltip:before {
  --tw-content: "";
  content: var(--tw-content);
}

.text-editor .ql-snow .ql-tooltip:not(.ql-hidden) {
  display: flex;
}

[data-qa-dropdown-item] > .text-editor .ql-snow .ql-tooltip a.ql-action, [data-qa-dropdown-item] > .text-editor .ql-snow .ql-tooltip a.ql-remove {
  margin-right: .5rem;
}

.btn > .text-editor .ql-snow .ql-tooltip a.ql-action, .btn > .text-editor .ql-snow .ql-tooltip a.ql-remove {
  margin-left: -2px;
}

.btn.text-editor .ql-snow .ql-tooltip a.ql-action, .btn .text-editor .ql-snow .ql-tooltip a.ql-remove {
  height: 2rem;
  width: 2rem;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.nav-hover > li > a > .text-editor .ql-snow .ql-tooltip a.ql-action, .nav-hover > li > a > .text-editor .ql-snow .ql-tooltip a.ql-remove {
  flex-shrink: 0;
  margin-right: .75rem;
  font-size: 1.15rem;
}

.text-editor .ql-snow .ql-tooltip a.ql-action, .text-editor .ql-snow .ql-tooltip a.ql-remove {
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  flex-shrink: 0;
  font-family: Material Symbols Rounded;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 401;
  line-height: 1.25;
}

:is([data-theme="dark"] .text-editor .ql-snow .ql-tooltip a.ql-action), :is([data-theme="dark"] .text-editor .ql-snow .ql-tooltip a.ql-remove) {
  font-weight: 300;
}

.carousel-prev.text-editor .ql-snow .ql-tooltip a.ql-action, .carousel-next.text-editor .ql-snow .ql-tooltip a.ql-action, .carousel-prev .text-editor .ql-snow .ql-tooltip a.ql-remove, .carousel-next .text-editor .ql-snow .ql-tooltip a.ql-remove {
  font-size: .8125rem;
}

.text-editor .ql-snow .ql-tooltip a.ql-action:hover, .text-editor .ql-snow .ql-tooltip a.ql-remove:hover {
  color: var(--text-heading);
}

.text-editor .ql-snow .ql-tooltip a.ql-remove {
  font-size: 1.25rem;
}

.text-editor .ql-snow .ql-tooltip a.ql-remove:before {
  --tw-content: "";
  content: var(--tw-content);
  margin: 0;
}

.text-editor .ql-snow .ql-tooltip a.ql-action {
  margin-right: .25rem;
  font-size: .9375rem;
}

.text-editor .ql-snow .ql-tooltip a.ql-action:after {
  --tw-content: "";
  content: var(--tw-content);
  border-width: 0;
  margin: 0;
  padding: 0;
}

.text-editor .ql-snow .ql-tooltip[data-mode="link"]:before {
  display: none;
}

.text-editor .ql-snow .ql-tooltip.ql-editing input[type="text"] {
  width: 10rem;
  color: var(--body-color);
  background-color: #0000;
  border-width: 0;
  padding-left: .75rem;
  padding-right: .75rem;
  font-family: Roboto, sans-serif;
}

.text-editor .ql-snow .ql-tooltip.ql-editing a.ql-action {
  font-size: 1.25rem;
}

.text-editor .ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  --tw-content: "";
  content: var(--tw-content);
}

.text-editor .ql-snow .ql-tooltip .ql-preview {
  width: 8rem;
  color: rgb(var(--theme-color));
  margin-right: .75rem;
  font-size: .6875rem;
}

.text-editor .ql-bold > svg {
  display: none;
}

.text-editor .ql-bold:before {
  content: "";
}

.text-editor .ql-italic > svg {
  display: none;
}

.text-editor .ql-italic:before {
  content: "";
}

.text-editor .ql-underline > svg {
  display: none;
}

.text-editor .ql-underline:before {
  content: "";
}

.text-editor .ql-link > svg {
  display: none;
}

.text-editor .ql-link:before {
  content: "";
}

.text-editor .ql-image > svg {
  display: none;
}

.text-editor .ql-image:before {
  content: "";
}

.text-editor .ql-list[value="ordered"]:before {
  content: "";
}

.text-editor .ql-list[value="bullet"]:before {
  content: "";
}

#calendar {
  --fc-button-bg-color: transparent;
  --fc-button-text-color: var(--text-heading);
  --fc-today-bg-color: transparent;
  --fc-event-border-color: transparent;
  --fc-page-bg-color: transparent;
}

#calendar.fc {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--body-bg) / var(--tw-bg-opacity));
}

#calendar.fc table tr:last-child td {
  border-bottom-width: 0;
}

#calendar.fc table td:last-child, #calendar.fc table th:last-child {
  border-right-width: 0;
}

#calendar.fc .fc-scroller {
  scrollbar-color: rgb(var(--theme-light-300)) transparent;
  scrollbar-width: thin;
  overflow: auto;
}

#calendar.fc .fc-scroller::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}

#calendar.fc .fc-scroller::-webkit-scrollbar-track {
  background-color: #0000;
}

#calendar.fc .fc-scroller::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-300) / var(--tw-bg-opacity));
  background-clip: content-box;
  border: 2px solid #0000;
  border-radius: .5rem;
}

:is([data-theme="dark"] #calendar.fc .fc-scroller)::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

:root[data-theme="dark"] #calendar.fc .fc-scroller {
  scrollbar-color: rgb(var(--theme-dark-300)) transparent;
}

#calendar.fc .fc-toolbar.fc-header-toolbar {
  z-index: 8;
  height: 3rem;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--body-bg) / var(--tw-bg-opacity));
  margin-bottom: 0;
  position: sticky;
  top: 0;
}

#calendar.fc .fc-toolbar-chunk {
  align-items: center;
  display: flex;
}

#calendar.fc .fc-toolbar-title {
  color: var(--text-heading);
  margin: 0;
  font-size: .8125rem;
  font-weight: 500;
}

#calendar.fc .fc-view-harness {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .375rem;
}

:is([data-theme="dark"] #calendar.fc .fc-view-harness) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-100) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

#calendar.fc .fc-button {
  height: 2rem;
  border-width: 0;
  border-radius: .2rem;
  padding: 0 .5rem;
  font-size: 0;
}

#calendar.fc .fc-button:focus, #calendar.fc .fc-button:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

[data-qa-dropdown-item] > #calendar.fc .fc-button:before {
  margin-right: .5rem;
}

.btn > #calendar.fc .fc-button:before {
  margin-left: -2px;
}

.btn#calendar.fc .fc-button:before {
  height: 2rem;
  width: 2rem;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.nav-hover > li > a > #calendar.fc .fc-button:before {
  flex-shrink: 0;
  margin-right: .75rem;
  font-size: 1.15rem;
}

#calendar.fc .fc-button:before {
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  flex-shrink: 0;
  font-family: Material Symbols Rounded;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 401;
  line-height: 1.25;
}

:is([data-theme="dark"] #calendar.fc .fc-button):before {
  font-weight: 300;
}

.carousel-prev#calendar.fc .fc-button:before, .carousel-next#calendar.fc .fc-button:before {
  font-size: .8125rem;
}

#calendar.fc .fc-button:disabled {
  opacity: 1;
}

[data-qa-dropdown-item] > #calendar.fc .fc-icon {
  margin-right: .5rem;
}

.btn > #calendar.fc .fc-icon {
  margin-left: -2px;
}

.btn#calendar.fc .fc-icon {
  height: 2rem;
  width: 2rem;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.nav-hover > li > a > #calendar.fc .fc-icon {
  flex-shrink: 0;
  margin-right: .75rem;
  font-size: 1.15rem;
}

#calendar.fc .fc-icon {
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  flex-shrink: 0;
  font-family: Material Symbols Rounded;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 401;
  line-height: 1.25;
}

:is([data-theme="dark"] #calendar.fc .fc-icon) {
  font-weight: 300;
}

.carousel-prev#calendar.fc .fc-icon, .carousel-next#calendar.fc .fc-icon {
  font-size: .8125rem;
}

#calendar.fc .fc-today-button:before {
  --tw-content: "";
  content: var(--tw-content);
}

#calendar.fc .fc-prev-button:before, #calendar.fc .fc-menu-button:before {
  --tw-content: "";
  content: var(--tw-content);
}

#calendar.fc .fc-next-button:before {
  --tw-content: "";
  content: var(--tw-content);
}

#calendar.fc .fc-dayGridMonth-button:before {
  --tw-content: "";
  content: var(--tw-content);
}

#calendar.fc .fc-timeGridWeek-button:before {
  --tw-content: "";
  content: var(--tw-content);
}

#calendar.fc .fc-timeGridDay-button:before {
  --tw-content: "";
  content: var(--tw-content);
}

#calendar.fc .fc-listWeek-button:before {
  --tw-content: "";
  content: var(--tw-content);
}

#calendar.fc .fc-next-button {
  margin-right: 1.25rem;
}

#calendar.fc .fc-scrollgrid {
  border-width: 0;
}

#calendar.fc .fc-col-header-cell, #calendar.fc th.fc-timegrid-axis {
  border-bottom-width: 0;
}

#calendar.fc .fc-col-header-cell {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  padding-top: .5rem;
  font-size: .6875rem;
  font-weight: 500;
}

#calendar.fc .fc-daygrid-day-top {
  color: var(--text-heading);
  justify-content: center;
}

#calendar.fc .fc-day-other .fc-daygrid-day-top {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  opacity: .7;
}

#calendar.fc .fc-daygrid-day-number {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

#calendar.fc .fc-day-today .fc-daygrid-day-number {
  height: 2rem;
  width: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 9999px;
  place-content: center;
  margin-bottom: 0;
  display: grid;
}

#calendar.fc .fc-h-event, #calendar.fc .fc-v-event {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
  border-width: 0;
  border-radius: .2rem;
  padding: .25rem .375rem;
  font-size: .6875rem;
}

#calendar.fc .fc-daygrid-dot-event {
  height: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
  border-radius: .2rem;
  padding-top: .125rem;
  padding-bottom: .125rem;
}

:is([data-theme="dark"] #calendar.fc .fc-daygrid-dot-event) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

#calendar.fc .fc-daygrid-event-dot {
  height: .75rem;
  width: .25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
  border-width: 0;
}

#calendar.fc .fc-event-time {
  font-size: .5625rem;
  font-weight: 500;
}

#calendar.fc .fc-event-title {
  font-weight: 400;
}

#calendar.fc .fc-event-main, #calendar.fc .fc-event-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#calendar.fc .fc-timegrid-axis, #calendar.fc .fc-timegrid-slot-label {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  font-size: .6875rem;
  font-weight: 500;
}

#calendar.fc .fc-timegrid-divider {
  display: none;
}

#calendar.fc .fc-daygrid-more-link {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  font-size: .6875rem;
}

#calendar.fc .fc-daygrid-more-link:hover {
  color: var(--text-heading);
}

#calendar.fc .fc-popover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .2rem;
  margin: -1px;
}

#calendar.fc .fc-popover-header {
  background-image: none;
  padding: .5rem;
  font-weight: 500;
}

#calendar.fc .fc-today-button {
  display: none;
}

@media (width >= 640px) {
  #calendar.fc .fc-today-button {
    display: block;
  }
}

#calendar.fc .fc-menu-button {
  margin-left: -.5rem;
  margin-right: .5rem;
}

@media (width >= 768px) {
  #calendar.fc .fc-menu-button {
    display: none;
  }
}

#calendar.fc .fc-daygrid-day-events {
  padding: .25rem;
}

#calendar-navigate .view-switch {
  pointer-events: none;
}

#calendar-navigate .datepicker-cell:not(.disabled):not(.selected):not(.today):hover, #calendar-navigate .prev-btn:hover, #calendar-navigate .next-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] #calendar-navigate .datepicker-cell:not(.disabled):not(.selected):not(.today):hover), :is([data-theme="dark"] #calendar-navigate .prev-btn:hover), :is([data-theme="dark"] #calendar-navigate .next-btn:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

.datepicker-dropdown {
  z-index: 50;
  width: 16rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  color: var(--body-color);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgb(var(--theme-light-500) / .2);
  --tw-shadow: var(--tw-shadow-colored);
  border-radius: .375rem;
  padding: .75rem;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

:is([data-theme="dark"] .datepicker-dropdown) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
  border-width: 0;
}

.datepicker-dropdown.active {
  display: block;
}

.datepicker-picker {
  width: 100%;
  font-size: .75rem;
}

.datepicker-picker .datepicker-cell {
  height: 2rem;
  border-radius: 9999px;
  place-content: center;
  line-height: 1;
  display: grid;
}

.datepicker-main {
  margin-left: -.125rem;
  margin-right: -.125rem;
  padding: 0;
}

.datepicker-view {
  padding: 0;
}

.datepicker-view .days {
  width: 100%;
}

.days-of-week {
  margin-bottom: .5rem;
}

.datepicker-grid, .days-of-week {
  width: 100%;
  text-align: center;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  display: grid;
}

.datepicker-grid.months, .datepicker-grid.years, .datepicker-grid.decades {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
}

.datepicker-header {
  height: auto;
  background-color: #0000;
  margin-bottom: 1rem;
  padding: 0;
}

.datepicker-header .datepicker-controls {
  padding: 0;
  display: flex;
}

.datepicker-header .datepicker-controls .view-switch {
  text-align: left;
  color: var(--text-heading);
  border-radius: .2rem;
  flex: 1;
  order: -1;
  padding: 0 .5rem;
  font-size: .8125rem;
  font-weight: 500;
}

.datepicker-header .datepicker-controls .view-switch:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .datepicker-header .datepicker-controls .view-switch:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

[data-qa-dropdown-item] > .datepicker-header .datepicker-controls .next-btn, [data-qa-dropdown-item] > .datepicker-header .datepicker-controls .prev-btn {
  margin-right: .5rem;
}

.btn > .datepicker-header .datepicker-controls .next-btn, .btn > .datepicker-header .datepicker-controls .prev-btn {
  margin-left: -2px;
}

.btn.datepicker-header .datepicker-controls .next-btn, .btn .datepicker-header .datepicker-controls .prev-btn {
  height: 2rem;
  width: 2rem;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.nav-hover > li > a > .datepicker-header .datepicker-controls .next-btn, .nav-hover > li > a > .datepicker-header .datepicker-controls .prev-btn {
  flex-shrink: 0;
  margin-right: .75rem;
  font-size: 1.15rem;
}

.datepicker-header .datepicker-controls .next-btn, .datepicker-header .datepicker-controls .prev-btn {
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  height: 1.75rem;
  width: 1.75rem;
  color: var(--text-heading);
  border-width: 0;
  border-radius: .2rem;
  flex-shrink: 0;
  margin-left: .5rem;
  font-family: Material Symbols Rounded;
  font-size: .8125rem;
  font-style: normal;
  font-weight: 401;
  line-height: 1.25;
}

:is([data-theme="dark"] .datepicker-header .datepicker-controls .next-btn), :is([data-theme="dark"] .datepicker-header .datepicker-controls .prev-btn) {
  font-weight: 300;
}

.carousel-prev.datepicker-header .datepicker-controls .next-btn, .carousel-next.datepicker-header .datepicker-controls .next-btn, .carousel-prev .datepicker-header .datepicker-controls .prev-btn, .carousel-next .datepicker-header .datepicker-controls .prev-btn {
  font-size: .8125rem;
}

.datepicker-header .datepicker-controls .next-btn:hover, .datepicker-header .datepicker-controls .prev-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .datepicker-header .datepicker-controls .next-btn:hover), :is([data-theme="dark"] .datepicker-header .datepicker-controls .prev-btn:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

.datepicker-view .dow {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  font-size: .6875rem;
  font-weight: 400;
}

.datepicker-cell {
  cursor: pointer;
}

.datepicker-cell.next:not(.disabled), .datepicker-cell.prev:not(.disabled) {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  opacity: .5;
}

.datepicker-cell.next.selected, .datepicker-cell.prev.selected {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.datepicker-cell.focused:not(.selected):not(.today) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .datepicker-cell.focused:not(.selected):not(.today)) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

.datepicker-cell.today.focused, .datepicker-cell.selected, .datepicker-cell.range-start, .datepicker-cell.range-end {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 500;
}

.datepicker-cell.range {
  background-color: rgb(var(--theme-color) / .1);
}

.datepicker-cell.today {
  color: var(--text-heading);
  background-color: #0000;
}

.datepicker-cell:not(.disabled):not(.selected):not(.focused):not(.range-start):not(.range-end):not(.range):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .datepicker-cell:not(.disabled):not(.selected):not(.focused):not(.range-start):not(.range-end):not(.range):hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

.datepicker-cell:not(.prev):not(.next) {
  font-weight: 500;
}

select.custom-select {
  display: none;
}

.custom-select.nice-select {
  height: 2.25rem;
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-300) / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  float: none;
  background-color: #0000;
  border-radius: .2rem;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .75rem;
  transition-property: none;
  display: flex;
}

:is([data-theme="dark"] .custom-select.nice-select) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-300) / var(--tw-border-opacity));
}

.custom-select.nice-select:hover, .custom-select.nice-select.open, .custom-select.nice-select:active, .custom-select.nice-select:focus {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-400) / var(--tw-border-opacity));
}

:is([data-theme="dark"] .custom-select.nice-select:hover), :is([data-theme="dark"] .custom-select.nice-select.open), :is([data-theme="dark"] .custom-select.nice-select:active), :is([data-theme="dark"] .custom-select.nice-select:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-300) / var(--tw-border-opacity));
}

.custom-select.nice-select.open {
  border-width: 0;
}

.custom-select.nice-select:after {
  height: .375rem;
  width: .375rem;
  opacity: .7;
  border-color: currentColor;
  border-bottom-width: 1px;
  border-right-width: 1px;
  right: 1rem;
}

.custom-select.nice-select .nice-select-dropdown {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-300) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
  border-radius: .375rem;
  margin-top: 0;
  transition-property: none;
  top: 0;
  overflow: hidden;
}

:is([data-theme="dark"] .custom-select.nice-select .nice-select-dropdown) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-200) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

.custom-select.nice-select .option {
  color: var(--body-color);
  border-radius: .2rem;
  margin-bottom: 1px;
  padding: .5rem .75rem;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.5;
}

.custom-select.nice-select .option:not(.disabled):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  color: var(--body-color);
}

:is([data-theme="dark"] .custom-select.nice-select .option:not(.disabled):hover) {
  background-color: rgb(var(--theme-dark-300) / .5);
}

.custom-select.nice-select .option.disabled {
  font-weight: 400;
}

.custom-select.nice-select .option.disabled, .custom-select.nice-select .option.disabled:hover, .custom-select.nice-select .option.disabled:focus {
  cursor: default;
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  opacity: .7;
}

.custom-select.nice-select .option.focus, .custom-select.nice-select .option.selected, .custom-select.nice-select .option.selected.focus {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
  color: var(--text-heading);
  align-items: center;
  font-weight: 500;
  display: flex;
  position: relative;
}

:is([data-theme="dark"] .custom-select.nice-select .option.focus), :is([data-theme="dark"] .custom-select.nice-select .option.selected), :is([data-theme="dark"] .custom-select.nice-select .option.selected.focus) {
  background-color: rgb(var(--theme-dark-300) / .5);
}

.custom-select.nice-select .option.selected:after {
  --tw-content: "";
  content: var(--tw-content);
  margin-left: auto;
  font-family: Material Symbols Rounded;
}

.custom-select.nice-select .list {
  padding: .25rem;
}

.custom-select.nice-select .nice-select-search-box {
  padding: 0;
}

.custom-select.nice-select .nice-select-search {
  height: 1.5rem;
  --tw-border-opacity: 1;
  border-width: 0;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
  color: var(--body-color);
  background-color: #0000;
  border-radius: 0;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .75rem;
}

.custom-select.nice-select .nice-select-search::placeholder {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
}

@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30%, 70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  20% {
    transform: scale(1);
  }
}

.dropzone, .dropzone * {
  box-sizing: border-box;
}

.dropzone {
  min-height: 150px;
  --tw-border-opacity: 1;
  border: 1px dashed #000c;
  border-color: rgb(var(--theme-light-500) / var(--tw-border-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
  border-radius: .2rem;
  padding: 1rem;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: .5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 3em 0;
  font-size: .75rem;
}

.dropzone .dz-message .dz-button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

.dropzone .dz-preview {
  vertical-align: top;
  min-height: 100px;
  margin: 16px;
  display: inline-block;
  position: relative;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  background: linear-gradient(#eee, #ddd);
  border-radius: 20px;
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
  background: none;
  border-radius: .375rem;
  padding: .25rem;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  transition: opacity .2s linear;
}

.dropzone .dz-preview .dz-remove {
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 14px;
  display: block;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  opacity: 0;
  min-width: 100%;
  max-width: 100%;
  text-align: center;
  color: #000000e6;
  padding: 2em 1em;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  background-color: #fffc;
  border: 1px solid #c8c8c8cc;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid #0000;
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
  background-color: #fff6;
  border-radius: 3px;
  padding: 0 .4em;
}

.dropzone .dz-preview:hover .dz-image img {
  filter: blur(8px);
  transform: scale(1.05);
}

.dropzone .dz-preview .dz-image {
  width: 120px;
  height: 120px;
  z-index: 10;
  border-radius: .2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  animation: 3s cubic-bezier(.77, 0, .175, 1) passing-through;
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  animation: 3s cubic-bezier(.77, 0, .175, 1) slide-in;
}

.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  background: #000c;
  border-radius: 50%;
  margin-top: -27px;
  margin-left: -27px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
  width: 54px;
  height: 54px;
  fill: #fff;
  display: block;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  transition: all .2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  transition: opacity .4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  animation: 6s infinite pulse;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  height: 20px;
  background: #000c;
  border: 3px solid #000c;
  border-radius: 10px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  left: 15%;
  right: 15%;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  height: 100%;
  width: 0;
  background: #fff;
  border-radius: 17px;
  transition: width .3s ease-in-out;
  display: block;
  position: relative;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  opacity: 0;
  width: 140px;
  color: #fff;
  background: #b10606;
  border-radius: 8px;
  padding: .5em 1em;
  font-size: 13px;
  transition: opacity .3s;
  display: none;
  position: absolute;
  top: 130px;
  left: -10px;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 6px solid #b10606;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  position: absolute;
  top: -6px;
  left: 64px;
}

.dropzone:hover {
  background-color: rgb(var(--theme-light-100) / .5);
}

:is([data-theme="dark"] .dropzone) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-300) / var(--tw-border-opacity));
}

:is([data-theme="dark"] .dropzone:hover) {
  background-color: rgb(var(--theme-dark-200) / .5);
}

:is([data-theme="dark"] .dropzone .dz-preview.dz-image-preview) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-200) / var(--tw-border-opacity));
}

.carousel {
  border-radius: .2rem;
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  gap: .375rem;
  display: flex;
}

.carousel-slide {
  min-width: 0;
  flex: 0 0 100%;
  position: relative;
}

.carousel-slide img {
  border-radius: .2rem;
}

.carousel-prev, .carousel-next {
  z-index: 10;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #ffffff4d;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin: auto 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-prev:hover, .carousel-next:hover {
  --tw-bg-opacity: .75;
  background-color: #ffffff80;
}

.carousel-prev.icon, .carousel-next.icon {
  font-size: .8125rem;
}

.carousel-prev {
  left: 0;
}

.carousel-next {
  right: 0;
}

.carousel-dots {
  width: 100%;
  background-color: #ffffff1a;
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
  justify-content: center;
  gap: .375rem;
  margin-top: .75rem;
  padding: .75rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.carousel-dot {
  height: .25rem;
  width: .75rem;
  cursor: pointer;
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #ffffff80;
  border-radius: 9999px;
  transition-property: colors, width;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.carousel-dot.active {
  width: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
}

.carousel-caption {
  width: 100%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 1.25rem;
  position: absolute;
  bottom: 1.75rem;
  left: 0;
}

[data-tippy-root] .tippy-box {
  background-color: var(--tooltip-bg);
}

[data-tippy-root] .tippy-content {
  color: var(--tooltip-text);
  padding: .5rem .75rem;
  font-size: .6875rem;
  line-height: 1.5;
}

[data-tippy-root] .tippy-arrow {
  color: var(--tooltip-bg);
}

[data-tippy-root] .tippy-arrow:before {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.\!left-auto {
  left: auto !important;
}

.\!right-0 {
  right: 0 !important;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-right-1 {
  right: -.25rem;
}

.-right-1\.5 {
  right: -.375rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-1\.5 {
  top: -.375rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-8 {
  top: -2rem;
}

.-top-\[0\.3rem\] {
  top: -.3rem;
}

.-top-px {
  top: -1px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-full {
  bottom: 100%;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-2 {
  left: .5rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-2 {
  top: .5rem;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-auto {
  top: auto;
}

.top-full {
  top: 100%;
}

.isolate {
  isolation: isolate;
}

.-z-\[1\] {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-\[18\] {
  z-index: 18;
}

.z-\[19\] {
  z-index: 19;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[6\] {
  z-index: 6;
}

.z-\[7\] {
  z-index: 7;
}

.z-\[8\] {
  z-index: 8;
}

.z-\[9999\] {
  z-index: 9999;
}

.-order-1 {
  order: -1;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.-m-3 {
  margin: -.75rem;
}

.-m-px {
  margin: -1px;
}

.m-0 {
  margin: 0;
}

.m-0\.5 {
  margin: .125rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-ml-\[2px\] {
  margin-left: -2px;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-2\.5 {
  margin-right: -.625rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-\[2px\] {
  margin-top: -2px;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-px {
  margin-bottom: 1px;
}

.me-2 {
  margin-inline-end: .5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[17px\] {
  height: 17px;
}

.h-\[9px\] {
  height: 9px;
}

.h-\[calc\(100vh_-_4rem\)\] {
  height: calc(100vh - 4rem);
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-\[3rem\] {
  min-height: 3rem;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[17px\] {
  width: 17px;
}

.w-\[800px\] {
  width: 800px;
}

.w-\[9px\] {
  width: 9px;
}

.w-\[var\(--sidebar-width\)\] {
  width: var(--sidebar-width);
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[7rem\] {
  min-width: 7rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[calc\(100\%_-_1\.5rem\)\] {
  max-width: calc(100% - 1.5rem);
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-none {
  max-width: none;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_100\%\] {
  flex: 0 0 100%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.origin-center {
  transform-origin: center;
}

.-translate-x-\[var\(--sidebar-width\)\] {
  --tw-translate-x: calc(var(--sidebar-width) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-4 {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[270deg\] {
  --tw-rotate: 270deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.animate-none {
  animation: none;
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-rows-\[auto_1fr\] {
  grid-template-rows: auto 1fr;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.self-start {
  align-self: flex-start;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .2rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-tl {
  border-top-left-radius: .2rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tr {
  border-top-right-radius: .2rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-\[1\.5px\] {
  border-width: 1.5px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[\#6a8ca3\] {
  --tw-border-opacity: 1;
  border-color: rgb(106 140 163 / var(--tw-border-opacity));
}

.border-blue-500\/20 {
  border-color: #3b82f633;
}

.border-blue-600\/90 {
  border-color: #2563ebe6;
}

.border-current {
  border-color: currentColor;
}

.border-cyan-600\/90 {
  border-color: #0891b2e6;
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-light-100 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-100) / var(--tw-border-opacity));
}

.border-light-200 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-200) / var(--tw-border-opacity));
}

.border-light-300 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-300) / var(--tw-border-opacity));
}

.border-light-400 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-400) / var(--tw-border-opacity));
}

.border-light-500 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-500) / var(--tw-border-opacity));
}

.border-orange-500\/20 {
  border-color: #f9731633;
}

.border-orange-600\/90 {
  border-color: #ea580ce6;
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-red-500\/20 {
  border-color: #ef444433;
}

.border-red-600\/90 {
  border-color: #dc2626e6;
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.border-teal-500\/20 {
  border-color: #14b8a633;
}

.border-teal-600\/90 {
  border-color: #0d9488e6;
}

.border-theme {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-color) / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-r-transparent {
  border-right-color: #0000;
}

.bg-\[\#127f74\] {
  --tw-bg-opacity: 1;
  background-color: rgb(18 127 116 / var(--tw-bg-opacity));
}

.bg-\[\#26a69a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(38 166 154 / var(--tw-bg-opacity));
}

.bg-\[\#5bbbb2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(91 187 178 / var(--tw-bg-opacity));
}

.bg-\[\#6a8ca3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(106 140 163 / var(--tw-bg-opacity));
}

.bg-\[\#92d2cc\] {
  --tw-bg-opacity: 1;
  background-color: rgb(146 210 204 / var(--tw-bg-opacity));
}

.bg-\[--tooltip-bg\] {
  background-color: var(--tooltip-bg);
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-500\/20 {
  background-color: #f59e0b33;
}

.bg-black\/20 {
  background-color: #0003;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-blue-200\/90 {
  background-color: #bfdbfee6;
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-500\/10 {
  background-color: #3b82f61a;
}

.bg-blue-500\/20 {
  background-color: #3b82f633;
}

.bg-blue-500\/80 {
  background-color: #3b82f6cc;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-600\/90 {
  background-color: #2563ebe6;
}

.bg-body {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--body-bg) / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-500\/10 {
  background-color: #06b6d41a;
}

.bg-cyan-500\/20 {
  background-color: #06b6d433;
}

.bg-cyan-500\/80 {
  background-color: #06b6d4cc;
}

.bg-cyan-600\/90 {
  background-color: #0891b2e6;
}

.bg-dark-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

.bg-dark-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-400) / var(--tw-bg-opacity));
}

.bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-500\/20 {
  background-color: #22c55e33;
}

.bg-green-500\/80 {
  background-color: #22c55ecc;
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-light-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
}

.bg-light-100\/50 {
  background-color: rgb(var(--theme-light-100) / .5);
}

.bg-light-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
}

.bg-light-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-300) / var(--tw-bg-opacity));
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-orange-500\/10 {
  background-color: #f973161a;
}

.bg-orange-500\/80 {
  background-color: #f97316cc;
}

.bg-orange-600\/90 {
  background-color: #ea580ce6;
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-purple-500\/20 {
  background-color: #a855f733;
}

.bg-purple-500\/80 {
  background-color: #a855f7cc;
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-500\/10 {
  background-color: #ef44441a;
}

.bg-red-500\/20 {
  background-color: #ef444433;
}

.bg-red-500\/80 {
  background-color: #ef4444cc;
}

.bg-red-600\/90 {
  background-color: #dc2626e6;
}

.bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.bg-teal-500\/10 {
  background-color: #14b8a61a;
}

.bg-teal-500\/20 {
  background-color: #14b8a633;
}

.bg-teal-600\/90 {
  background-color: #0d9488e6;
}

.bg-theme {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
}

.bg-theme\/10 {
  background-color: rgb(var(--theme-color) / .1);
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-white\/30 {
  background-color: #ffffff4d;
}

.bg-white\/40 {
  background-color: #fff6;
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-white\/75 {
  background-color: #ffffffbf;
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-\[image\:var\(--close-bg\)\] {
  background-image: var(--close-bg);
}

.bg-\[image\:var\(--faq-header-bg\)\] {
  background-image: var(--faq-header-bg);
}

.bg-\[url\:var\(--checkbox-checked-bg\)\] {
  background-image: var(--checkbox-checked-bg);
}

.bg-\[url\:var\(--radio-checked-bg\)\] {
  background-image: var(--radio-checked-bg);
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#f7fafe\] {
  --tw-gradient-to: #f7fafe var(--tw-gradient-to-position);
}

.bg-\[length\:10px\] {
  background-size: 10px;
}

.bg-\[length\:9px_9px\] {
  background-size: 9px 9px;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.fill-transparent {
  fill: #0000;
}

.stroke-current {
  stroke: currentColor;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[1\.5px\] {
  padding: 1.5px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[0\.35rem\] {
  padding-left: .35rem;
  padding-right: .35rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[0\.15rem\] {
  padding-top: .15rem;
  padding-bottom: .15rem;
}

.\!pl-8 {
  padding-left: 2rem !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-\[0\.35rem\] {
  padding-left: .35rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-icon {
  font-family: Material Symbols Rounded;
}

.font-sans {
  font-family: Roboto, sans-serif;
}

.text-2xl {
  font-size: 1.25rem;
}

.text-3xl {
  font-size: 1.5625rem;
}

.text-4xl {
  font-size: 1.875rem;
}

.text-\[0\] {
  font-size: 0;
}

.text-\[1\.15rem\] {
  font-size: 1.15rem;
}

.text-base {
  font-size: .8125rem;
}

.text-lg {
  font-size: .9375rem;
}

.text-md {
  font-size: .75rem;
}

.text-sm {
  font-size: .6875rem;
}

.text-xl {
  font-size: 1.0625rem;
}

.text-xs {
  font-size: .5625rem;
}

.font-\[401\] {
  font-weight: 401;
}

.font-bold {
  font-weight: 500;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.text-\[--tooltip-bg\] {
  color: var(--tooltip-bg);
}

.text-\[--tooltip-text\] {
  color: var(--tooltip-text);
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-body {
  color: var(--body-color);
}

.text-current {
  color: currentColor;
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-heading {
  color: var(--text-heading);
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-link {
  color: rgb(var(--theme-color));
}

.text-muted {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

.text-theme {
  --tw-text-opacity: 1;
  color: rgb(var(--theme-color) / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_0\.25rem_rgba\(104\,138\,160\,0\.1\)\] {
  --tw-shadow: 0 0 0 .25rem #688aa01a;
  --tw-shadow-colored: 0 0 0 .25rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[shadow\:var\(--dropdown-menu-shadow\)\] {
  --tw-shadow: var(--dropdown-menu-shadow);
  --tw-shadow-colored: var(--dropdown-menu-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-light-500\/20 {
  --tw-shadow-color: rgb(var(--theme-light-500) / .2);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-theme\/20 {
  --tw-shadow-color: rgb(var(--theme-color) / .2);
  --tw-shadow: var(--tw-shadow-colored);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-\[3px\] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\!ring-white\/20 {
  --tw-ring-color: #fff3 !important;
}

.ring-light-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-light-100) / var(--tw-ring-opacity));
}

.ring-light-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-light-200) / var(--tw-ring-opacity));
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-\[colors\,width\] {
  transition-property: colors, width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[height\] {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[margin-top\] {
  transition-property: margin-top;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,shadow\] {
  transition-property: transform, shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.content-\[\"\"\] {
  --tw-content: "";
  content: var(--tw-content);
}

.content-\[\"\#\{\$icon\}\"\] {
  --tw-content: "#{$icon}";
  content: var(--tw-content);
}

.content-\[\"\\e5cf\"\] {
  --tw-content: "";
  content: var(--tw-content);
}

.content-\[\'\\e876\'\] {
  --tw-content: "";
  content: var(--tw-content);
}

.underline, .hover\:underline:hover {
  text-underline-offset: 2px;
}

:root {
  --theme-light-100: 240 247 252;
  --theme-light-200: 229 239 248;
  --theme-light-300: 217 231 243;
  --theme-light-400: 206 223 238;
  --theme-light-500: 196 215 233;
  --theme-dark-100: 41 60 72;
  --theme-dark-200: 49 72 87;
  --theme-dark-300: 60 86 103;
  --theme-dark-400: 86 117 138;
  --theme-dark-500: 104 138 160;
  --body-light-bg: 247 250 254;
  --body-dark-bg: 34 49 58;
  --sidebar-width: 15rem;
  --sidebar-min-width: 3.875rem;
  --tooltip-bg: #1f2e36;
  --tooltip-color: #e0f3ff;
}

.scrollbar {
  scrollbar-color: rgb(var(--theme-light-300)) transparent;
  scrollbar-width: thin;
  overflow: auto;
}

.scrollbar::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #0000;
}

.scrollbar::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-300) / var(--tw-bg-opacity));
  background-clip: content-box;
  border: 2px solid #0000;
  border-radius: .5rem;
}

:is([data-theme="dark"] .scrollbar)::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

:root[data-theme="dark"] .scrollbar {
  scrollbar-color: rgb(var(--theme-dark-300)) transparent;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media print {
  @page {
    margin: 0;
    size: auto;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
  }

  .print-content {
    -webkit-print-color-adjust: exact !important;
    width: 100% !important;
    border: 0 !important;
    padding: 2rem !important;
  }
}

:root:not([data-theme="dark"])[data-theme-color="blue"] {
  --theme-color-light: 17 130 238;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(17 130 238)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"])[data-theme-color="green"] {
  --theme-color-light: 16 185 129;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(16 185 129)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"])[data-theme-color="indigo"] {
  --theme-color-light: 99 102 241;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(99 102 241)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"])[data-theme-color="orange"] {
  --theme-color-light: 251 146 60;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(251 146 60)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"])[data-theme-color="purple"] {
  --theme-color-light: 168 85 247;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(168 85 247)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"])[data-theme-color="red"] {
  --theme-color-light: 248 113 113;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(248 113 113)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"])[data-theme-color="cyan"] {
  --theme-color-light: 6 182 212;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(6 182 212)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"])[data-theme-color="teal"] {
  --theme-color-light: 20 184 166;
  --theme-radio-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgb(20 184 166)'/%3e%3c/svg%3e");
}

:root:not([data-theme="dark"]) {
  --theme-color: var(--theme-color-light);
  --body-bg: var(--body-light-bg);
  --body-color: #37507f;
  --text-heading: #23416f;
  --text-muted: #627da7;
  --chart-previous-data-start-fill: #b6d8f533;
  --chart-previous-data-end-fill: #b6d8f500;
  --chart-previous-data-border-fill: #b6d8f5;
  --widget-session-country-initial-fill: #ffedd1;
  --widget-session-country-active-fill: #ffc107;
  --widget-active-visitor-initial-fill: #e0f2fe;
  --widget-active-visitor-active-fill: #38bdf8;
  --widget-sales-region-initial-fill: #dcfce7;
  --widget-sales-region-active-fill: #4ade80;
  --chart-pie-border: #fff;
  --close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2337507f'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
  --scrollbar-thumb-bg: #d4e5f5;
  --radio-checked-bg: var(--theme-radio-bg);
  --fc-border-color: rgb(var(--theme-light-200));
  --fc-button-hover-bg-color: rgb(var(--theme-light-200));
  --fc-button-active-bg-color: rgb(var(--theme-light-200));
  --fc-neutral-bg-color: rgb(var(--theme-light-200));
}

:root[data-theme="dark"] {
  --theme-color: 17 130 238;
  --body-bg: var(--body-dark-bg);
  --body-color: #a1c3d8;
  --text-heading: #c8e0ef;
  --text-muted: #6a8ca3;
  --chart-previous-data-start-fill: #45677f33;
  --chart-previous-data-end-fill: #45677f00;
  --chart-previous-data-border-fill: #45677f;
  --widget-session-country-initial-fill: #c0ca332b;
  --widget-session-country-active-fill: #c0ca33;
  --widget-active-visitor-initial-fill: #075985bf;
  --widget-active-visitor-active-fill: #0ea5e9;
  --widget-sales-region-initial-fill: #4ade8026;
  --widget-sales-region-active-fill: #31b963;
  --chart-pie-border: rgb(var(--theme-dark-100));
  --close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a1c3d8'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
  --scrollbar-thumb-bg: #bad2e8;
  --radio-checked-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231182ee' /%3e%3c/svg%3e");
  --fc-border-color: rgb(var(--theme-dark-100));
  --fc-button-hover-bg-color: rgb(var(--theme-dark-100));
  --fc-button-active-bg-color: rgb(var(--theme-dark-200));
  --fc-neutral-bg-color: rgb(var(--theme-dark-100));
}

.color-picker {
  width: 100%;
}

.color-picker::part(saturation) {
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
}

hex-color-picker.color-picker::part(hue), hsl-string-color-picker.color-picker::part(hue) {
  border-bottom-right-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

rgba-string-color-picker.color-picker::part(alpha) {
  border-bottom-right-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.input-slider.noUi-horizontal {
  height: .125rem;
}

.input-slider.noUi-horizontal .noUi-handle {
  height: .75rem;
  width: .75rem;
  top: -.3rem;
  right: -.375rem;
}

.input-slider .noUi-handle {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-color) / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 0;
  border-radius: 9999px;
  transition-property: transform, shadow;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-slider .noUi-handle:before, .input-slider .noUi-handle:after {
  display: none;
}

.input-slider .noUi-handle.noUi-active {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 0 0 .25rem #688aa01a;
  --tw-shadow-colored: 0 0 0 .25rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.input-slider .noUi-connect {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--blue) / var(--tw-bg-opacity));
}

.input-slider.noUi-target {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-300) / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 0;
}

:is([data-theme="dark"] .input-slider.noUi-target) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

.placeholder\:text-muted::placeholder {
  --tw-text-opacity: 1;
  color: rgb(106 140 163 / var(--tw-text-opacity));
}

.placeholder\:text-white::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:left-\[1\.75rem\]:before {
  content: var(--tw-content);
  left: 1.75rem;
}

.before\:left-\[3\.4rem\]:before {
  content: var(--tw-content);
  left: 3.4rem;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-\[1\.9rem\]:before {
  content: var(--tw-content);
  top: 1.9rem;
}

.before\:m-0:before {
  content: var(--tw-content);
  margin: 0;
}

.before\:h-2\/4:before {
  content: var(--tw-content);
  height: 50%;
}

.before\:h-\[calc\(100\%_-_0\.8rem\)\]:before {
  content: var(--tw-content);
  height: calc(100% - .8rem);
}

.before\:h-\[calc\(100\%_-_3\.8rem\)\]:before {
  content: var(--tw-content);
  height: calc(100% - 3.8rem);
}

.before\:w-\[calc\(100\%_-_2rem\)\]:before {
  content: var(--tw-content);
  width: calc(100% - 2rem);
}

.before\:w-\[calc\(100\%_-_3\.6rem\)\]:before {
  content: var(--tw-content);
  width: calc(100% - 3.6rem);
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:rounded:before {
  content: var(--tw-content);
  border-radius: .2rem;
}

.before\:bg-gradient-to-b:before {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.before\:from-black\/90:before {
  content: var(--tw-content);
  --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:to-black\/0:before {
  content: var(--tw-content);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
}

.before\:content-\[\"\"\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[\"\\e888\"\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[\'—\'\]:before {
  --tw-content: "—";
  content: var(--tw-content);
}

.after\:m-0:after {
  content: var(--tw-content);
  margin: 0;
}

.after\:border-0:after {
  content: var(--tw-content);
  border-width: 0;
}

.after\:p-0:after {
  content: var(--tw-content);
  padding: 0;
}

.after\:content-\[\"\\e3c9\"\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\"\\e92d\"\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.focus-within\:text-heading:focus-within {
  color: var(--text-heading);
}

.hover\:w-\[var\(--sidebar-width\)\]:hover {
  width: var(--sidebar-width);
}

.hover\:border-light-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-light-300) / var(--tw-border-opacity));
}

.hover\:bg-blue-500\/20:hover {
  background-color: #3b82f633;
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.hover\:bg-light-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-100) / var(--tw-bg-opacity));
}

.hover\:bg-light-100\/50:hover {
  background-color: rgb(var(--theme-light-100) / .5);
}

.hover\:bg-light-100\/75:hover {
  background-color: rgb(var(--theme-light-100) / .75);
}

.hover\:bg-light-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
}

.hover\:bg-light-200\/60:hover {
  background-color: rgb(var(--theme-light-200) / .6);
}

.hover\:bg-light-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-300) / var(--tw-bg-opacity));
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.hover\:bg-red-500\/10:hover {
  background-color: #ef44441a;
}

.hover\:bg-red-500\/20:hover {
  background-color: #ef444433;
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.hover\:bg-teal-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.hover\:bg-theme\/10:hover {
  background-color: rgb(var(--theme-color) / .1);
}

.hover\:bg-white\/10:hover {
  background-color: #ffffff1a;
}

.hover\:bg-white\/20:hover {
  background-color: #fff3;
}

.hover\:bg-white\/75:hover {
  background-color: #ffffffbf;
}

.hover\:bg-none:hover {
  background-image: none;
}

.hover\:hover\:text-white:hover:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-body:hover {
  color: var(--body-color);
}

.hover\:text-heading:hover {
  color: var(--text-heading);
}

.hover\:text-link:hover {
  color: rgb(var(--theme-color));
}

.hover\:text-theme:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--theme-color) / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.focus\:bg-slate-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:placeholder\:text-body:focus::placeholder {
  color: var(--body-color);
}

.disabled\:cursor-default:disabled {
  cursor: default;
}

.disabled\:bg-blue-600\/90:disabled {
  background-color: #2563ebe6;
}

.disabled\:opacity-75:disabled {
  opacity: .75;
}

.disabled\:hover\:bg-blue-600\/90:hover:disabled {
  background-color: #2563ebe6;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group:hover .group-hover\:text-link {
  color: rgb(var(--theme-color));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.peer:checked ~ .peer-checked\:line-through {
  text-decoration-line: line-through;
}

.aria-expanded\:bg-light-200[aria-expanded="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
}

.aria-expanded\:bg-white\/20[aria-expanded="true"] {
  background-color: #fff3;
}

.data-\[te-dropdown-show\]\:bg-blue-600[data-te-dropdown-show] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:border-0) {
  border-width: 0;
}

:is([data-theme="dark"] .dark\:border-none) {
  border-style: none;
}

:is([data-theme="dark"] .dark\:border-blue-600) {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

:is([data-theme="dark"] .dark\:border-dark-100) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-100) / var(--tw-border-opacity));
}

:is([data-theme="dark"] .dark\:border-dark-200) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-200) / var(--tw-border-opacity));
}

:is([data-theme="dark"] .dark\:border-dark-300) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--theme-dark-300) / var(--tw-border-opacity));
}

:is([data-theme="dark"] .dark\:border-green-700) {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

:is([data-theme="dark"] .dark\:border-red-700) {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

:is([data-theme="dark"] .dark\:border-r-transparent) {
  border-right-color: #0000;
}

:is([data-theme="dark"] .dark\:bg-\[\#1b2931\]) {
  --tw-bg-opacity: 1;
  background-color: rgb(27 41 49 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-amber-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-blue-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-cyan-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-dark-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-dark-100\/50) {
  background-color: rgb(var(--theme-dark-100) / .5);
}

:is([data-theme="dark"] .dark\:bg-dark-100\/60) {
  background-color: rgb(var(--theme-dark-100) / .6);
}

:is([data-theme="dark"] .dark\:bg-dark-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-dark-200\/50) {
  background-color: rgb(var(--theme-dark-200) / .5);
}

:is([data-theme="dark"] .dark\:bg-dark-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-dark-300\/50) {
  background-color: rgb(var(--theme-dark-300) / .5);
}

:is([data-theme="dark"] .dark\:bg-dark-400\/20) {
  background-color: rgb(var(--theme-dark-400) / .2);
}

:is([data-theme="dark"] .dark\:bg-dark-400\/40) {
  background-color: rgb(var(--theme-dark-400) / .4);
}

:is([data-theme="dark"] .dark\:bg-dark-400\/60) {
  background-color: rgb(var(--theme-dark-400) / .6);
}

:is([data-theme="dark"] .dark\:bg-dark-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-500) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-green-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-lime-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:bg-teal-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:to-\[\#22313a\]) {
  --tw-gradient-to: #22313a var(--tw-gradient-to-position);
}

:is([data-theme="dark"] .dark\:text-amber-600) {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

:is([data-theme="dark"] .dark\:text-black) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

:is([data-theme="dark"] .dark\:text-blue-600) {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

:is([data-theme="dark"] .dark\:text-cyan-600) {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

:is([data-theme="dark"] .dark\:text-green-600) {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

:is([data-theme="dark"] .dark\:text-heading) {
  color: var(--text-heading);
}

:is([data-theme="dark"] .dark\:text-purple-600) {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

:is([data-theme="dark"] .dark\:text-red-600) {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

:is([data-theme="dark"] .dark\:shadow-none) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is([data-theme="dark"] .dark\:ring-dark-100) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--theme-dark-100) / var(--tw-ring-opacity));
}

:is([data-theme="dark"] .dark\:placeholder\:text-body)::placeholder {
  color: var(--body-color);
}

:is([data-theme="dark"] .dark\:hover\:bg-dark-100:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:hover\:bg-dark-100\/50:hover) {
  background-color: rgb(var(--theme-dark-100) / .5);
}

:is([data-theme="dark"] .dark\:hover\:bg-dark-100\/60:hover) {
  background-color: rgb(var(--theme-dark-100) / .6);
}

:is([data-theme="dark"] .dark\:hover\:bg-dark-200:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-200) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:hover\:bg-dark-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-300) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:hover\:bg-dark-300\/50:hover) {
  background-color: rgb(var(--theme-dark-300) / .5);
}

:is([data-theme="dark"] .dark\:hover\:text-body:hover) {
  color: var(--body-color);
}

:is([data-theme="dark"] .dark\:hover\:text-heading:hover) {
  color: var(--text-heading);
}

:is([data-theme="dark"] .dark\:aria-expanded\:bg-dark-100[aria-expanded="true"]) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

@media print {
  .print\:hidden {
    display: none;
  }
}

@media (width >= 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:max-w-\[355px\] {
    max-width: 355px;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  .md\:relative {
    position: relative;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:max-w-7xl {
    max-width: 80rem;
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:rounded {
    border-radius: .2rem;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:pt-0 {
    padding-top: 0;
  }
}

@media (width >= 1024px) {
  .lg\:relative {
    position: relative;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:w-\[var\(--sidebar-width\)\] {
    width: var(--sidebar-width);
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:columns-2 {
    columns: 2;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:pr-64 {
    padding-right: 16rem;
  }
}

@media (width >= 1280px) {
  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:-top-2 {
    top: -.5rem;
  }

  .xl\:-top-2\.5 {
    top: -.625rem;
  }

  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:w-96 {
    width: 24rem;
  }

  .xl\:w-\[var\(--sidebar-min-width\)\] {
    width: var(--sidebar-min-width);
  }

  .xl\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:pl-4 {
    padding-left: 1rem;
  }

  .xl\:pl-\[var\(--sidebar-min-width\)\] {
    padding-left: var(--sidebar-min-width);
  }

  .xl\:pl-\[var\(--sidebar-width\)\] {
    padding-left: var(--sidebar-width);
  }

  .xl\:text-xl {
    font-size: 1.0625rem;
  }

  .xl\:font-bold {
    font-weight: 500;
  }

  .xl\:text-muted {
    --tw-text-opacity: 1;
    color: rgb(106 140 163 / var(--tw-text-opacity));
  }

  .xl\:text-transparent {
    color: #0000;
  }

  .xl\:opacity-0 {
    opacity: 0;
  }

  .xl\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:duration-100 {
    transition-duration: .1s;
  }

  .xl\:content-\[\"\.\.\.\"\] {
    --tw-content: "...";
    content: var(--tw-content);
  }
}

@media (width >= 1536px) {
  .\32 xl\:columns-3 {
    columns: 3;
  }

  .\32 xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

.\[\&\.show\]\:bg-light-200.show {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-light-200) / var(--tw-bg-opacity));
}

:is([data-theme="dark"] .dark\:\[\&\.show\]\:bg-dark-100.show) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--theme-dark-100) / var(--tw-bg-opacity));
}

.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: #0009;
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: #0009;
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: none;
  border: none;
  padding: 3px 5px;
  display: inline-block;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover, .ql-snow .ql-toolbar button:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type="file"], .ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active), .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-snow, .ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin, .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-snow .ql-transparent {
  opacity: .4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: .83em;
}

.ql-snow .ql-editor h6 {
  font-size: .67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-snow .ql-picker-options {
  min-width: 100%;
  white-space: nowrap;
  background-color: #fff;
  padding: 4px 8px;
  display: none;
  position: absolute;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid #0000;
  margin: 2px;
  padding: 0;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label:before, .ql-snow .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label:before, .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before, .ql-snow .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid #0000;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid #0000;
  box-shadow: 0 2px 8px #0003;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Edit";
  border-right: 1px solid #ccc;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Save";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}

.ql-snow a {
  color: #06c;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}

.nice-select {
  -webkit-tap-highlight-color: #0000;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  float: left;
  height: 38px;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  line-height: 36px;
  transition: all .2s ease-in-out;
  display: block;
  position: relative;
  text-align: left !important;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  content: "";
  height: 5px;
  pointer-events: none;
  transform-origin: 66% 66%;
  width: 5px;
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  margin-top: -4px;
  transition: all .15s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: rotate(45deg);
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .nice-select-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1)translateY(0);
}

.nice-select.disabled {
  color: #999;
  pointer-events: none;
  border-color: #ededed;
}

.nice-select.disabled:after {
  border-color: #ccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .nice-select-dropdown {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .nice-select-dropdown {
  left: auto;
  right: 0;
}

.nice-select.small {
  height: 36px;
  font-size: 12px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  min-height: 34px;
  line-height: 34px;
}

.nice-select .nice-select-dropdown {
  pointer-events: none;
  transform-origin: 50% 0;
  z-index: 9;
  opacity: 0;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 4px;
  transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
  position: absolute;
  top: 100%;
  left: 0;
  transform: scale(.75)translateY(19px);
  box-shadow: 0 0 0 1px #4444441c;
}

.nice-select .list {
  box-sizing: border-box;
  max-height: 210px;
  border-radius: 5px;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: #0000 !important;
}

.nice-select .option {
  cursor: pointer;
  text-align: left;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  transition: all .2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  color: #999;
  cursor: default;
  background-color: #0000;
}

.nice-select .optgroup {
  font-weight: bold;
}

.no-csspointerevents .nice-select .nice-select-dropdown {
  display: none;
}

.no-csspointerevents .nice-select.open .nice-select-dropdown {
  display: block;
}

.nice-select .list::-webkit-scrollbar {
  width: 0;
}

.nice-select .has-multiple {
  white-space: inherit;
  height: auto;
  min-height: 36px;
  padding: 7px 12px;
  line-height: 22px;
}

.nice-select .has-multiple span.current {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
}

.nice-select .has-multiple .multiple-options {
  padding: 0;
  line-height: 24px;
  display: block;
}

.nice-select .nice-select-search-box {
  box-sizing: border-box;
  width: 100%;
  pointer-events: none;
  border-radius: 5px 5px 0 0;
  padding: 5px;
}

.nice-select .nice-select-search {
  box-sizing: border-box;
  color: #444;
  vertical-align: middle;
  width: 100%;
  min-height: 36px;
  height: auto;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  margin: 0 10px 0 0;
  padding: 7px 12px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  outline: 0 !important;
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.noUi-connects {
  z-index: 0;
  overflow: hidden;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  z-index: 1;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-style: flat;
  position: absolute;
  top: 0;
  right: 0;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

.noUi-vertical .noUi-origin {
  width: 0;
  top: -100%;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  right: -17px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  bottom: -17px;
  right: -6px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  background: #fafafa;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  cursor: default;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:before, .noUi-handle:after {
  content: "";
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  display: block;
  position: absolute;
  top: 6px;
  left: 14px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  color: #999;
  position: absolute;
}

.noUi-value {
  white-space: nowrap;
  text-align: center;
  position: absolute;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  background: #ccc;
  position: absolute;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  height: 80px;
  width: 100%;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  height: 100%;
  padding: 0 10px;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  padding-left: 25px;
  transform: translate(0, -50%);
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  color: #000;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 5px;
  display: block;
  position: absolute;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
}

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  transform: translate(0, -50%);
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  bottom: 10px;
  left: auto;
  transform: translate(50%);
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  top: auto;
  right: 28px;
  transform: translate(0, -18px);
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.simplebar-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
  width: auto !important;
  height: auto !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
}

.simplebar-content-wrapper {
  direction: inherit;
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: block;
  position: relative;
  overflow: auto;
  box-sizing: border-box !important;
}

.simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-content:after, .simplebar-content:before {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  height: 100%;
  width: 100%;
  max-width: 1px;
  float: left;
  max-height: 1px;
  z-index: -1;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  opacity: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  pointer-events: none;
  z-index: -1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging, [data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  min-height: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #000;
  border-radius: 7px;
  transition: opacity .2s linear .5s;
  position: absolute;
  left: 2px;
  right: 2px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition-duration: 0s;
  transition-delay: 0s;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-scrollbar:before {
  inset: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  min-height: 0;
  min-width: 10px;
  width: auto;
  inset: 0 auto 0 0;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  position: fixed;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: scrollbar !important;
}

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.simplebar-hide-scrollbar {
  visibility: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

/*# sourceMappingURL=app.cbca1e80.css.map */
