@layer components {
	[data-qa-dropdown-menu] {
		@apply absolute
        top-full
        z-50
        hidden
        min-w-[7rem]
        rounded-md
        border
        border-light-200
        bg-white
        text-body
        shadow-lg
        shadow-light-500/20
        dark:border-0
        dark:bg-dark-200
        dark:shadow-black/20;
	}

	[data-qa-dropdown] {
		@apply inline-block;

		&.active {
			[data-qa-dropdown-menu] {
				@apply block;
			}
		}
	}

	[data-qa-dropdown-toggle] {
		* {
			@apply pointer-events-none;
		}
	}

	[data-qa-dropdown-item] {
		@apply flex
        items-center
        whitespace-nowrap
        rounded
        py-1.5
        pl-2.5
        pr-4
        text-md
        text-body
        transition-colors
        hover:bg-light-100
        hover:text-heading
        dark:hover:bg-dark-300/50;

		& > .icon {
			@apply mr-2;
		}
	}
}
