@layer components {
    .caret {
        &:after {
            border-width: 0 0 7px 7px;
            border-color: transparent transparent currentColor transparent;
    
            @apply
                absolute
                right-0
                top-0
                content-[""]
                w-0
                h-0
                border-solid;
        }
    }
}