// Dark/Light mode SCSS map
// In order to use this mixin, you must provide a SCSS map.
// Please check the existing usage of this mixin.
@mixin whenDark {
	:root {
		&[data-theme="dark"] {
			@content;
		}
	}
}

@mixin whenLight {
	:root {
		&:not([data-theme="dark"]) {
			@content;
		}
	}
}

@mixin theme-switch($map) {
	@each $property, $value in $map {
		$light: nth($value, 1);
		$dark: nth($value, 2);

		@include whenLight() {
			#{$property}: #{$light};
		}

		@include whenDark {
			#{$property}: #{$dark};
		}
	}
}

// Form control
@mixin form-control() {
	@apply h-9
		w-full
		rounded
		border
		border-light-300
		bg-transparent
		px-3
		shadow-none
		dark:border-dark-300;
}

@mixin form-control-focus() {
	@apply border-light-500
		shadow-none
		dark:border-dark-500;
}

@mixin form-control-disable() {
	@apply bg-light-100
		text-muted opacity-70
		dark:bg-dark-200/60;
}
