@layer base {
	.table {
		@apply w-full
        text-left;

		th,
		td {
			@apply border-b
            border-light-100
			p-3
            dark:border-dark-200;
		}

		th {
			@apply font-bold;
		}
	}
}
